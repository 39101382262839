.Toastify__toast-container {
  font-family: 'Inter';
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  min-width: 442px;
  min-height: 48px;
  font-size: 14px;
  background: #f1f0f7;
  color: #0b0b0c;
}
.Toastify__toast--success {
  min-width: 442px;
  min-height: 48px;
  font-size: 14px;
  background: #66a860;
}
.Toastify__toast--warning {
  min-width: 442px;
  min-height: 48px;
  font-size: 14px;
  background: yellow;
}
.Toastify__toast--error {
  min-width: 442px;
  min-height: 48px;
  font-size: 14px;
  background: #c6355c;
}

.Toastify__progress-bar--success {
  background: #c1e0be;
}
.Toastify__progress-bar--error {
  background: #ed85a1;
}

.Toastify__progress-bar--info {
  background: #6e26fc;
}
