.Draftail-Editor {
  background-color: transparent;
  border: 0px solid #ddd;
  border-radius: 0px;
}
.Draftail-Editor--hide-placeholder .public-DraftEditorPlaceholder-root {
  display: block;
  font-family: 'Inter';
  color: #85839b;
}

.Draftail-Editor .DraftEditor-root {
  color: #181441;
  /* color: #85839b; */
  /* font-size: 1rem; */
  font-size: 1rem;
  font-family: 'Inter';
  /* font-variant-ligatures: none; */
  overflow: auto;
}

.Draftail-Editor .public-DraftEditorPlaceholder-inner {
  color: #85839b;
  font-size: 16px;
}
