.custom-styles {
  --ReactInputVerificationCode-itemWidth: 60px;
  --ReactInputVerificationCode-itemHeight: 104px;
  --ReactInputVerificationCode-itemSpacing: 8px;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #0b0b0c;
  font-weight: 600;
  font-family: 'Inter';
  font-size: 24;
  box-shadow: none;
  background-color: #f1f0f7;
}

.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: inset 0 0 0 2px #6e26fc;
}

.custom-styles .ReactInputVerificationCode__item:after {
  transition: background-color 0.2s ease-out;
}
